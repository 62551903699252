import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetcher, queries } from '.';
import { createOrder, deletePendingOrder } from '../api/shop';
import { useAppContext } from '../providers/AppProvider';

export const useOrderDetail = orderNo => useFetcher(queries.orderDetail, { orderNo });

export const useOrderList = (pagingParams) => {
    return useFetcher(queries.orderList, {
        paging:pagingParams,
    });
};

export const useCreateOrder = () => {
    const queryClient = useQueryClient();
    const { shopId, lang } = useAppContext();

    return useMutation(createOrder, {
        onSuccess: () => {
            queryClient.invalidateQueries(queries.orderList({ shopId, lang }).queryKey);
        },
    });
};

export const useDeletePendingOrder = () => {
    const { shopId, lang } = useAppContext();

    const mutationFn = async ({ pendingOrderNo }) => {
        return deletePendingOrder({
            shopId,
            lang,
            pendingOrderNo
        });
    }
    return useMutation({ mutationFn });
};
