import {
    useIsMutating,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { Toast } from 'native-base';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { queries, useFetcher } from '.';
import {
    addShopProductToCart,
    deleteCartItem,
    postPaymentAction,
    updateCartItem,
    updateCartRemark,
} from '../api/shop';
import { useAppContext } from '../providers/AppProvider';
import { FormFieldName } from '../store/constants/ShoppingCart';
import { trimCountingFromDetail } from '../utils/reactQuery';
import { useSelectedStock } from '../providers/SelectStockProvider';

export const useCartCounting = () => {
    const params = null;
    const cartDetail = useCartDetail();
    const cartDetailLoaded = !! cartDetail.data?.inventories;
    const cartCount = (cartDetailLoaded ? trimCountingFromDetail(cartDetail): {});
    return useFetcher(queries.cartCounting, params, {
        enabled: cartDetailLoaded,
        initialData: cartCount,
    });
};

export const useCheckoutOption = () => useFetcher(queries.checkoutOption);

export const useCartDetail = (props) => {
    const {
        withShippingCost = false, 
    } = props || {};

    const selectedStock = useSelectedStock();

    const { form = {}, deliveryOption = {} } = useSelector(
        state => state.shoppingCart,
    );
    const shippingMethodId = deliveryOption.shippingMethodId;
    const shippingCountryId = form[FormFieldName.country];
    const promotionCode = form[FormFieldName.promotionCode];
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();

    let params = {};

    if (selectedStock.length > 0) {
        params['selectedStock'] = selectedStock;
    }

    if (withShippingCost) {
        params['shippingMethodId'] = shippingMethodId;
        params['shippingCountryId'] = shippingCountryId;
        params['promotionCode'] = promotionCode;
    }
    
    return useFetcher(queries.cartDetail, params, {
        keepPreviousData: true,
        onSuccess: (data) => {
            queryClient.setQueryData(
                queries.cartCounting({shopId, lang}).queryKey,
                trimCountingFromDetail(data),
            );
        },
    });
};

export const useAddToCart = () => {
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(addShopProductToCart, {
        onSuccess: data => {
            queryClient.setQueryData(
                queries.cartCounting({shopId, lang}).queryKey,
                data.data,
            );
            queryClient.invalidateQueries(queries.cartDetail({shopId, lang}).queryKey);
        },
        onError: error => {
            if (error?.response?.data?.errorMsgs) {
                error.response.data.errorMsgs.forEach(({ code }) => {
                    if (code === 'SCA003') {
                        Toast.show({
                            description: t('SCA003'),
                        });
                    }
                });
            }
        },
    });
};

export const useCartMutationKey = () => {
    const { shopId, lang } = useAppContext();
    return queries.cart({shopId, lang}).queryKey;
};

export const useIsCartMutating = () => {
    const mutationKey = useCartMutationKey();
    const isMutating = useIsMutating(mutationKey);

    return isMutating > 0;
};

export const useUpdateCartItem = () => {
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();
    const mutationKey = useCartMutationKey();
    const selectedStock = useSelectedStock();

    return useMutation(updateCartItem, {
        mutationKey,
        onSuccess: (data, variables) => {
            // todo: invalidate product query
            if (selectedStock && selectedStock.length > 0) {
                queryClient.invalidateQueries(queries.cartDetail({shopId, lang, selectedStock}).queryKey);
            } else {
                queryClient.setQueryData(
                    queries.cartDetail({shopId, lang}).queryKey,
                    data.data,
                );
            }
            
            queryClient.setQueryData(
                queries.cartCounting({shopId, lang}).queryKey,
                trimCountingFromDetail(data.data),
            );
        },
    });
};

export const useDeleteCartItem = () => {
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();
    const mutationKey = useCartMutationKey();

    return useMutation(deleteCartItem, {
        mutationKey,
        onSuccess: (data) => {
            queryClient.setQueriesData(
                queries.cartDetail({shopId, lang}).queryKey,
                data.data,
            );
            queryClient.setQueryData(
                queries.cartCounting({shopId, lang}).queryKey,
                trimCountingFromDetail(data.data),
            );
        },
    });
};

export const useUpdateCartRemark = () => {
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();
    const mutationKey = useCartMutationKey();
    const selectedStock = useSelectedStock();

    return useMutation(updateCartRemark, {
        mutationKey,
        onSuccess: (data) => {
            queryClient.setQueriesData(
                queries.cartDetail({shopId, lang, selectedStock}).queryKey,
                data.data,
            );
        },
    });
};
