import { useCallback, useContext, useMemo, useState } from 'react';
import { createContext } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

const SelectStockContext = createContext({
  selectedStock: [],
  setSelectedStock: () => {},
});

const SelectStockProvider = ({selectedStockStoreKey, isCheckout = false, children}) => {
  const [selectedStock, setSelectedStock] = useState([]);
  const savedSelectedStock = useSelector(state => state.product.selectedStock?.[selectedStockStoreKey]);

  const value = useMemo(() => {
    if (isCheckout) {
      return {
        selectedStock: savedSelectedStock || [],
        setSelectedStock: () => {},
      };
    }
    
    return {
      selectedStock,
      setSelectedStock,
    }
  }, [selectedStock, setSelectedStock, selectedStockStoreKey, savedSelectedStock, isCheckout]);

  return (
    <SelectStockContext.Provider value={value}>
      {children}
    </SelectStockContext.Provider>
  );
};

const useSelectStock = () => {
  const { setSelectedStock } = useContext(SelectStockContext);

  return useCallback((stockIdList) => {
    if (!setSelectedStock) return;

    setSelectedStock((selectedStock) => 
      _.chain(selectedStock).concat(stockIdList).uniq().value()
    );
  }, [setSelectedStock]);
};

const useUnselectStock = () => {
  const { setSelectedStock } = useContext(SelectStockContext);

  return useCallback((stockIdList) => {
    if (!setSelectedStock) return;

    setSelectedStock((selectedStock) =>
      _.difference(selectedStock, stockIdList)
    );
  }, [setSelectedStock]);
};

const useResetSelectedStock = () => {
  const { setSelectedStock } = useContext(SelectStockContext);

  return useCallback(() => {
    if (!setSelectedStock) return;

    setSelectedStock([]);
  }, [setSelectedStock]);
};

const useSelectedStock = () => {
  const { selectedStock } = useContext(SelectStockContext);

  return useMemo(() => selectedStock, [selectedStock]);
}

export { SelectStockProvider, useSelectStock, useUnselectStock, useResetSelectedStock, useSelectedStock };