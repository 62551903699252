import { Text } from 'react-native';
import React from 'react';
import { useRouteError } from 'react-router-dom';

export default () => {
    const error = useRouteError();

    return (
        <>
            <Text>{error.message}</Text>
            {error.response && error.response.data && (
                <>
                    <Text>{error.response.data.errorCode}</Text>
                    <Text>{error.response.data.errorMsg}</Text>
                </>
            )}
        </>
    );
};
