import React, { useState } from 'react';
import {
    Image,
    Modal,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native';
import { allStyles } from './FormStyles';
import { useTranslateFunc } from './FormUtils';
import TitleLabel from './TitleLabel';

const styles = StyleSheet.create(allStyles);

export default function FieldSelectModalEditable(props) {
    const [visible, setVisible] = useState(false);

    const temp = props.getItemsByName(props.name);
    const items = Array.isArray(temp) ? temp : [];
    const message = typeof temp === 'string' ? temp : '';

    const translate = useTranslateFunc();

    let errorPlaceholder = '';

    if (!props.title && props.error && !props.hideError) {
        errorPlaceholder = props.error;
    }

    return (
        <View style={[styles.fieldContainer, props.containerStyle]}>
            {errorPlaceholder ? (
                <Text
                    style={[
                        styles.red,
                        {
                            position: 'absolute',
                            top: -20,
                            left: 25,
                            fontSize: 14,
                        },
                    ]}>
                    {errorPlaceholder}
                </Text>
            ) : (
                <></>
            )}
            <TitleLabel {...props} />
            <View
                disabled={(props?.editable === false)}
                style={[
                    styles.selectContainer, 
                    typeof props.value === 'string' && props.value.length > 0 ? {
                        backgroundColor: '#FFFFFF',
                        borderWidth: StyleSheet.hairlineWidth,
                        borderColor: '#212121',
                    } : {},
                ]}
                >
                <View style={{flex:5}}>
                <TextInput
                    editable={props?.editable}
                    style={[
                        styles.placeholderStyle,
                        { color: '#262626', },
                    ]}
                    value={props.value || ''}
                    keyboardType={props.keyboardType}
                    onChange={e => {
                        props.onChange({
                            name: props.name,
                            value: e.nativeEvent.text,
                        });
                    }}
                    onBlur={() => {
                        props.onBlur(props.name);
                    }}
                    />
                </View>
                <TouchableOpacity
                    onPress={() => {
                        setVisible(true);
                    }}
                    style={[{ flex: 1, height:"100%",  alignItems: 'center', justifyContent: 'center' }]}
                    >
                    <Image
                        style={[styles.selectIcon ]}
                        resizeMode={'contain'}
                        source={require('../../assets/images/awesome-sort-down.png')}
                        alt={''}
                        />
                </TouchableOpacity>
            </View>
            <Modal visible={visible} transparent={true} animationType={'fade'}>
                <TouchableOpacity
                    style={styles.modalBackdrop}
                    activeOpacity={1}
                    onPress={() => {
                        setVisible(false);
                    }}>
                    <View style={styles.modalContainer}>
                        <Text style={styles.modalTitle}>
                            {props.title
                                ? `${translate(['FORM', 'ENTER'])}${translate(
                                    props.title,
                                )}`
                                : translate(props.placeholder)}
                        </Text>
                        <ScrollView
                            style={{ marginTop: 16 }}
                            contentContainerStyle={{
                                paddingHorizontal: 20,
                                paddingBottom: 20,
                            }}
                            showsVerticalScrollIndicator={false}>
                            {message.length > 0 && (
                                <Text
                                    style={[
                                        styles.text,
                                        { textAlign: 'center' },
                                    ]}>
                                    {message}
                                </Text>
                            )}
                            {items.map((item, i) => (
                                <TouchableOpacity
                                    key={i}
                                    style={styles.modalSelectItemContainer}
                                    onPress={() => {
                                        setVisible(false);
                                        props.onChange({
                                            name: props.name,
                                            value: item.value,
                                        });
                                    }}>
                                    <View style={{ flexGrow: 1 }}>
                                        <Text style={styles.title}>
                                            {item.label}
                                        </Text>
                                        {item.subLabel && (
                                            <Text style={styles.text}>
                                                {item.subLabel}
                                            </Text>
                                        )}
                                    </View>
                                    <View
                                        style={
                                            props.value === item.value
                                                ? styles.modalSelectItemBubbleSeleted
                                                : styles.modalSelectItemBubble
                                        }
                                    />
                                </TouchableOpacity>
                            ))}
                        </ScrollView>
                    </View>
                </TouchableOpacity>
            </Modal>
        </View>
    );
}
