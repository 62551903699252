import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { makeQueryFn, queries, useFetcher } from '.';
import { getMemberLoginLink, login, logout } from '../api/shop';
import { useSetIsLoggedIn } from '../hooks/useIsLoggedIn';
import { pageRoutes } from '../Routes';
import { useGeneratePathFunc } from '../utils/routeUtils';
import { useAppContext } from '../providers/AppProvider';
import { Toast } from 'native-base';

export const useShopOGInfo = (shopShortName, shopDomain) => useFetcher(queries.shopOgInfo, { shopShortName, shopDomain });

export const useShopInfo = () => useFetcher(queries.shopInfo);

export const useShopLanding = () => useFetcher(queries.shopLanding);

export const useShopAddressList = () => useFetcher(queries.shopAddress);

export const useLogout = (redirect = true) => {
    const navigate = useNavigate();
    const setIsLoggedIn = useSetIsLoggedIn();
    const generatePath = useGeneratePathFunc();
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();

    return useMutation(logout, {
        onSuccess: () => {
            setIsLoggedIn(false);
            queryClient.invalidateQueries(queries.cartDetail({shopId, lang}).queryKey);
            if (redirect) {
                const url = generatePath(pageRoutes.shop.path);
                navigate(url);
            }
        },
        onError: error => {
            if (
                error?.response?.data?.errorMsgs &&
                Array.isArray(error.response.data.errorMsgs)
            ) {
                let isLoggedOut = false;
                error.response.data.errorMsgs.forEach(errorMsg => {
                    if (errorMsg.code === 'NotLoggedIn') {
                        isLoggedOut = true;
                    }
                });

                if (isLoggedOut) {
                    setIsLoggedIn(false);
                }
            }
        },
    });
};

export const useLogin = () => {
    const setIsLoggedIn = useSetIsLoggedIn();
    return useMutation(getMemberLoginLink, {
        onSuccess: data => {
            if (data?.data?.data?.loginUrl) {
                window.location.replace(data?.data?.data?.loginUrl);
            }
        },
        onError: error => {
            if (
                error?.response?.data?.errorMsgs &&
                Array.isArray(error.response.data.errorMsgs)
            ) {
                let isLoggedIn = false;
                error.response.data.errorMsgs.forEach(errorMsg => {
                    if (errorMsg.code === 'LoggedIn') {
                        isLoggedIn = true;
                    }
                });

                if (isLoggedIn) {
                    setIsLoggedIn(true);
                }
            }
        },
    });
};

export const useMemberLogin = (redirect = true, redirectPath = null) => {
    const navigate = useNavigate();
    const setIsLoggedIn = useSetIsLoggedIn();
    const generatePath = useGeneratePathFunc();
    const { shopId, lang } = useAppContext();
    const queryClient = useQueryClient();

    const success = () => {
        setIsLoggedIn(true);
        queryClient.invalidateQueries(queries.cartDetail({shopId, lang}).queryKey);

        if (redirect) {
            let url = generatePath(pageRoutes.orders.path);
            if (redirectPath){
                url = generatePath(redirectPath);
            }
            navigate(url);
        }
    };

    return useMutation(login, {
        onSuccess: () => {
            success();
        },
        onError: error => {
            if (
                error?.response?.data?.errorMsgs &&
                Array.isArray(error.response.data.errorMsgs)
            ) {
                let isLoggedIn = false;
                error.response.data.errorMsgs.forEach(errorMsg => {
                    if (errorMsg.code === 'LoggedIn') {
                        isLoggedIn = true;
                    }
                    if (redirect && errorMsg.message) {
                        Toast.show({
                            description: errorMsg.message,
                        });
                    }
                });

                if (isLoggedIn) {
                    success();
                }
            }
        },
    });
};

export const usePageContent = (page) => useFetcher(queries.shopPageContent, { page });

export const usePureLogout = () => {
    const setIsLoggedIn = useSetIsLoggedIn();

    const mutationFn = async({ shopId, lang }) => {
        return logout({
            shopId, lang,
        });
    };

    return useMutation(mutationFn, {
        onSuccess: () => {
            setIsLoggedIn(false);
        },
    })
}

export const usePureLogin = () => {
    const setIsLoggedIn = useSetIsLoggedIn();

    const mutationFn = async({ shopId, lang, memberId }) => {
        return login({
            shopId, lang, memberId,
        });
    };

    return useMutation(mutationFn, {
        onSuccess: () => {
            setIsLoggedIn(true);
        },
    });
};

export const useShopInfoQuery = ({ shopId, lang, enabled }) => {
    const { queryKey, queryMeta } = queries.shopInfo({ shopId, lang });
    const queryFn = makeQueryFn(queryMeta);
    const { mutate: logout } = useLogout();
  
    return useQuery({ queryKey, queryFn, enabled }, {
      onError: (error) => {
        if (error?.response?.status === 403) {
          logout({ shopId, lang });
        }
      }
    });
  };
  