import { HStack, Icon, Image, Text, VStack } from 'native-base';
import React from 'react';
import FontAwesome5 from 'react-native-vector-icons/dist/FontAwesome5';
import Ionicons from 'react-native-vector-icons/dist/Ionicons';
import { pageRoutes } from '../Routes';
import useCurrentPage from '../utils/useCurrentPage';
import { useTranslation } from 'react-i18next';
import { useIsLoggedIn } from '../hooks/useIsLoggedIn';

export const CheckoutMemberInfoType = {
    normal: 'normal',
    thankYouOrdering: 'thankYouOrdering',
    history: 'history',
    record: 'record',
};

const CheckoutMemberInfo = ({ memberInfo = {}, earnPoints }) => {
    const { estimatedPointsEarned, estimatedUpgradeRemainPoints = 0 } =
        memberInfo;

    const isLoggedIn = useIsLoggedIn();
    const currentPage = useCurrentPage();
    const hideBadge =
        currentPage.name === pageRoutes.orderSuccess.name ||
        currentPage.name === pageRoutes.orderDetails.name;

    const { t } = useTranslation();

    return (
        <VStack space={'5'}>
            {!hideBadge && (
                <HStack
                    alignSelf={'flex-start'}
                    borderRadius={'xl'}
                    space={'2'}
                    padding={'2'}
                    backgroundColor={'lightText'}>
                    <Icon
                        size={'5'}
                        as={Ionicons}
                        name={'checkmark'}
                        color={'white'}
                    />
                    <Text color={'white'}>{t('Logged in member')}</Text>
                </HStack>
            )}
            <HStack>
                {(estimatedPointsEarned || (earnPoints != null && earnPoints > 0)) && (
                    <VStack flex={1}>
                        <Text fontWeight={'medium'}>
                            {t('Earned points') + ':'}
                        </Text>
                        <HStack alignItems={'baseline'} marginTop={-2}>
                            <Icon
                                as={FontAwesome5}
                                name={'coins'}
                                size={'8'}
                                marginRight={'4'}
                                color={'lightText'}
                            />
                            <Text fontSize={'5xl'} bold>
                                {(_.isNumber(estimatedPointsEarned) ? estimatedPointsEarned.toLocaleString('en-US', {minimumFractionDigits: 0,}) : earnPoints.toLocaleString('en-US', {minimumFractionDigits: 0,}))}
                            </Text>
                            <Text bold>{t('POINTS')}</Text>
                        </HStack>
                    </VStack>
                )}
                {/* <VStack flex={1}>
                    <Text fontWeight={'medium'}>{'是次消費會籍升級:'}</Text>
                    <HStack alignItems={'baseline'} marginTop={-2}>
                        <Image rounded={false} resizeMode={'contain'} width={'8'} height={'8'} marginRight={'4'} tintColor={'lightText'} source={require('../assets/images/member_up.png')} alt={''}/>
                        <Text bold>{'尚餘'}</Text>
                        <Text fontSize={'5xl'} bold>{estimatedUpgradeRemainPoints}</Text>
                        <Text bold>{'分'}</Text>
                    </HStack>
                </VStack> */}
            </HStack>
        </VStack>
    );
};

export default CheckoutMemberInfo;
