import { Checkbox, HStack, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useIsCartMutating } from '../queries/cart';
import { useResetSelectedStock, useSelectStock, useSelectedStock } from '../providers/SelectStockProvider';

const ShoppingCartItemHeader = ({ isNarrow = false, products = [] }) => {
    const { t } = useTranslation();

    const selectedStock = useSelectedStock();
    const selectStocks = useSelectStock();
    const resetSelectedStocks = useResetSelectedStock();

    const allStockIdList = _.chain(products).map('stockId').uniq().value();

    const unselectedCount = _.difference(allStockIdList, selectedStock).length;

    const isChecked = unselectedCount === 0;

    const isDisabled = useIsCartMutating();

    return (
        <HStack
            space={'5'}
            paddingBottom={'2.5'}
            marginY={'5'}
            borderBottomWidth={'1'}
            borderBottomColor={'warmGray.300'}>
            {isNarrow ? (
                <Text flex={1} bold>
                    {t('Shopping Bag')}
                </Text>
            ) : (
                <>
                    <HStack flex={1} space={'5'} alignItems={'center'}>
                        <Checkbox 
                            accessibilityLabel={'all'}
                            isDisabled={isDisabled}
                            isChecked={isChecked} 
                            onChange={(value) => {
                                if (value) {
                                    selectStocks(allStockIdList);
                                } else {
                                    resetSelectedStocks();
                                }
                            }}
                        />
                        <Text bold>
                            {t('Item')}
                        </Text>
                    </HStack>
                    <Text flex={2} bold>
                        {''}
                    </Text>
                    <Text flex={1} bold>
                        {t('Price')}
                    </Text>
                    <Text flex={1} bold>
                        {t('Quantity')}
                    </Text>
                    <Text flex={1} bold>
                        {t('PROMOTION')}
                    </Text>
                    <Text flex={1} bold>
                        {t('Subtotal')}
                    </Text>
                    <Text flex={1} bold>
                        {''}
                    </Text>
                </>
            )}
        </HStack>
    );
};

export default ShoppingCartItemHeader;
