import React from 'react';
import { ActivityIndicator, Text, View, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { usePathMaker } from '../utils/routeUtils';

export const RNCenterLoading = ({ error, showReloadButton }) => {
    const { t } = useTranslation();
    const homeLink = usePathMaker.shop();

    return (
        <View
            style={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            {error ? (
                <Text>{error}</Text>
            ) : showReloadButton ? (
                <TouchableOpacity onPress={() => {
                    if (homeLink) {
                        window.location.replace(homeLink);
                    } else {
                        window.location.reload();
                    }
                }}>
                    <Text>{t('ERROR_RELOAD')}</Text>
                </TouchableOpacity>
            ) : (
                <ActivityIndicator color={'#6e6e6e'} />
            )}
        </View>
    );
};
