import * as ShoppingCart from '../constants/ShoppingCart';
import { action, createAction } from 'typesafe-actions';

export const addProductAction = (product, sku, quantity) =>
    action(ShoppingCart.ADD_PRODUCT, { product, sku, quantity });
export const deduceProductAction = product =>
    action(ShoppingCart.DEDUCE_PRODUCT, { product });
export const removeProductAction = sku =>
    action(ShoppingCart.REMOVE_PRODUCT, { sku });

export const useCouponAction = coupon =>
    action(ShoppingCart.USE_COUPON, { coupon });
export const unuseCouponAction = coupon =>
    action(ShoppingCart.UNUSE_COUPON, { coupon });

export const updateFormAction = form =>
    action(ShoppingCart.UPDATE_FORM, { form });

export const setDeliveryOptionAction = deliveryOption =>
    action(ShoppingCart.SET_DELIVERY_OPTION, { deliveryOption });
export const setDeliveryOptionSuboptioinAction = (shippingMethodId, optionId) =>
    action(ShoppingCart.SET_DELIVERY_OPTION_SUBOPTION, {
        shippingMethodId,
        optionId,
    });
export const setDeliveryOptionTextAction = (shippingMethodId, text) =>
    action(ShoppingCart.SET_DELIVERY_OPTION_TEXT, { shippingMethodId, text });

export const setPaymentOptionAction = paymentOption =>
    action(ShoppingCart.SET_PAYMENT_OPTION, { paymentOption });

export const dropShoppingCartAction = () =>
    action(ShoppingCart.DROP_SHOPPING_CART);
export const resetFormAction = () => action(ShoppingCart.RESET_FORM);

export const savePendingOrderNoAction = pendingOrderNo =>
    action(ShoppingCart.SAVE_PENDING_ORDER_NO, { pendingOrderNo });
export const deletePendingOrderNoAction = () =>
    action(ShoppingCart.DELETE_PENDING_ORDER_NO);
