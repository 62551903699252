import { HStack, VStack, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HtmlText from './HtmlText';

const Column = ({ title = '', text = '', element }) => {
    return (
        <VStack flex={1}>
            <Text bold>{title}</Text>
            <Text>{text ?? '-'}</Text>
            {element}
        </VStack>
    );
};

const Row = ({ titleList = [], textList = [], elementList = [] }) => {
    return (
        <HStack space={'5'}>
            {titleList.map((title, i) => (
                <Column
                    flex={1 / titleList.length}
                    key={i}
                    title={title}
                    text={textList[i]}
                    element={elementList[i]}
                />
            ))}
        </HStack>
    );
};

const OrderDetailContactView = ({ order = {} }) => {
    const {
        orderNo,
        orderAt,
        paymentStatus,
        shippingStatus,
        paymentMethod,
        paymentDescription,
        buyerName,
        recipientName,
        recipientPhone,
        recipientEmail,
        shippingCountry,
        shippingRegion,
        shippingAddress1,
        shippingAddress2,
        shippingTrackCode,
        shippingMethod,
        shippingMethodOption,
        shippingMethodType,
        expectDeliveryDate,
        expectDeliveryPeriod,
    } = order;

    const recipentAddress =
        shippingMethodType === 'ADDRESS'
            ? [
                  shippingCountry,
                  shippingRegion,
                  shippingAddress1,
                  shippingAddress2,
              ]
                  .filter(i => i)
                  .join(', ')
            : [shippingMethod, shippingMethodOption].filter(i => i).join(' - ');
    const { t } = useTranslation();

    return (
        <VStack space={'5'}>
            <Row
                titleList={[t('Order no.'), t('Order date')]}
                textList={[orderNo, orderAt]}
            />

            {paymentMethod ? (
                <>
                    <Row
                        titleList={[t('Payment status'), t('Delivery status')]}
                        textList={[paymentStatus, shippingStatus]}
                    />
                    <Row
                        titleList={[t('Payment method'), '']}
                        textList={[paymentMethod, '']}
                        elementList={[<HtmlText content={paymentDescription}/>]}
                    />
                </>
            ) : (
                <Row
                    titleList={[t('Delivery status')]}
                    textList={[shippingStatus]}
                />
            )}
            <Row
                titleList={[t('Customer name'), t('Recipient name')]}
                textList={[buyerName, recipientName]}
            />
            <Row
                titleList={[t('Recipient phone'), t('Recipient email')]}
                textList={[recipientPhone, recipientEmail]}
            />
            <Row
                titleList={[t('Delivery address'), t('Track code')]}
                textList={[recipentAddress, shippingTrackCode]}
            />
            {expectDeliveryDate && (
                <Row
                    titleList={[
                        t('FORM.delivery_date'),
                        t('FORM.delivery_time'),
                    ]}
                    textList={[expectDeliveryDate, expectDeliveryPeriod]}
                />
            )}
        </VStack>
    );
};

export default OrderDetailContactView;
