import * as Member from '../constants/Member';

const initialState = {
    id: '1',
    name: 'Ashes',
    points: 1000,
    expiryDate: '2023-05-30',
    isLoggedIn: false,
    isApp: false,
};

const MemberReducer = (state = initialState, action) => {
    if (action.type === Member.LOGIN) {
        return {
            ...state,
            isLoggedIn: true,
        };
    } else if (action.type === Member.LOGOUT) {
        return {
            ...state,
            isLoggedIn: false,
        };
    } else if (action.type === Member.SET_IS_APP) {
        const { isApp } = action.payload;
        return {
            ...state,
            isApp,
        };
    }
    return state;
};

export default MemberReducer;