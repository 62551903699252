import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const AVAILBLE_LOCALES = {
    tc: 'zh_hant',
    sc: 'zh_hans',
    en: 'en',
};

const resources = {
    en: {
        translation: {
            Categories: 'Categories',
            Sorting: 'Sorting',
            recommended: 'Recommended',
            priceDesc: 'Price: High to Low',
            priceAsc: 'Price: Low to High',
            timeDesc: 'Sorting: New to Old',
            timeAsc: 'Sorting: Old to New',
            Search: 'Search',
            'Terms and conditions': 'Terms and Conditions',
            'Privacy policy': 'Privacy Policy',
            TECH_SUPPORT: '© {{year}}, {{shopName}} | Powered by Mobile.Cards',
            TECH_SUPPORT_MOBILE: '© {{year}}, {{shopName}} | Powered by Mobile.Cards | <a href="{{tncLink}}">Terms and Conditions</a> | <a href="{{privacyLink}}">Privacy Policy</a>', 
            About: 'About',
            Us: 'About Us',
            Address: 'Address',
            Membership: 'Membership',
            Faq: 'Frequently Asked Questions',
            'Delivery policy': 'Delivery Policy',
            'Return policy': 'Return Policy',
            'Shopping cart': 'Shopping Cart',
            Item: 'Item',
            Price: 'Unit Price',
            Quantity: 'Quantity',
            Subtotal: 'Subtotal',
            'Shopping Bag': 'Shopping Bag',
            'Delivery fee': 'Delivery Fee',
            Total: 'Total',
            'Used points': 'Used Points',
            ':': ':',
            'Continue to checkout': 'Continue to Checkout',
            'Checkout': 'Checkout',
            Contacts: 'Contacts',
            Color: 'Color',
            'Add to cart': 'Add to Cart',
            'Add to favorite': 'Add to Favorite',
            'Added to favorite': 'Added to Favorite',
            Description: 'Description',
            'Delivery and payment method': 'Delivery and Payment Method',
            SOLDOUT: 'Sold Out',
            FEW: 'Few',
            TOTAL_PRODUCTS: 'Total of {{total}} product(s)',
            MEMBER_GREETING: 'Hi, {{name}}',
            'Logged in member': 'Logged In Member',
            'Earned points': 'Earned Points',
            'Current points': 'Current Points',
            'Points expiry date': 'Points Expiry Date',
            'Order details': 'Order Details',
            'Contact infomation': 'Contact Information',
            Delivery: 'Delivery',
            Payment: 'Payment',
            FORM: {
                Name: 'Name',
                'Country code': 'Country Code',
                'Phone number': 'Phone Number',
                'Email address': 'Email Address',
                'Delivery address': 'Delivery Address',
                'Country or region': 'Country or Region',
                Area: 'Area',
                'Address line 1': 'Address Line 1',
                'Address line 2': 'Address Line 2',
                'Recipient information': 'Recipient Information',
                'Save address': 'Save Address',
                'Same as above': 'Same as above',
                ENTER: 'Please Enter ',
                INVALID_INPUT: 'Invalid input ',
                preorder: 'Preorder',
                delivery_date: 'Expected Delivery Date',
                delivery_time: 'Expected Delivery Time',
            },
            'Go to next step': 'Go to Next Step',
            'Go to delivery': 'Go to Delivery',
            'Back to shopping cart': 'Back to Shopping Cart',
            'Go to payment': 'Go to Payment',
            Back: 'Back',
            'Delivery address': 'Delivery Address',
            'Delivery options': 'Delivery Options',
            'Delivery method': 'Delivery Method',
            Pay: 'Pay',
            'Payment methods': 'Payment Methods',
            'Payment failed, please try again':
                'Payment failed, please try again',
            'Your order has been received': 'Order Received!',
            'Back to shop': 'Back to e-Shop',
            'Order information': 'Order Information',
            EMAIL_ORDER:
                "The information will be sent to will be sent to your email.",
            'Order no.': 'Order No.',
            'Order date': 'Order Date',
            'Payment status': 'Payment Status',
            'Delivery status': 'Delivery Status',
            'Payment method': 'Payment Method',
            'Transaction no.': 'Transaction No.',
            'Customer name': 'Customer Name',
            'Recipient name': 'Recipient Name',
            'Recipient phone': 'Recipient Phone',
            'Recipient email': 'Recipient Email',
            Member: 'Member',
            'My orders': 'My Orders',
            'Order records': 'Order Records',
            'Order status here': 'You can check your order status here.',
            ORDER_DETAIL_BUTTON: 'Details',
            DETAIL_BUTTON: 'Details',
            'My favorite': 'My Favorite',
            ADDED_TO_CART: 'Added to Shopping Cart',
            'Logout': 'Logout',
            en: 'English',
            tc: '繁體中文',
            sc: '简体中文',
            th: 'ไทย',
            'Track code': 'Track Code',
            'Shopping cart is empty': 'Your Cart is Empty',
            'Up to pieces': 'Up to {{quantity}} pieces in a single purchase',
            'Up to piece': 'Up to {{quantity}} piece in a single purchase',
            'Remain piece': 'still can add {{quantity}} piece to cart',
            'Remain pieces': 'still can add {{quantity}} pieces to cart',
            'Purchase limit reached': 'Purchase limit reached',
            WARNING_MEMBER_POINTS_REQUIRED:
                'This product need to be purchase with points, please login / register as a member.',
            WARNING_MEMBER_REQUIRED: 'Please login / register as a member.',
            WARNING_MEMBER_GRADE_REQUIRED: 'This product is for member only.',
            WARNING_MEMBER_GRADE_NOT_ELIGIBLE: 'The following member can purchase this product:',
            TITLE_DOWNLOAD_APP:'Downlaod member app',
            FOOTER_DOWNLOAD_APP:
                'Earn reward points each time you make a purchase and enjoy all the member offers! All you have to do is join member with phone number, no password is needed.',
            ORDER_STATUS_UPDATED: 'Order status has been updated',
            PICKUP_QRCODE: 'Show the QR Code when picking up',
            Remark: 'Remark',
            SKU: 'SKU',
            Confirm: 'Confirm',
            'Purchase limit exceeded': 'Purchase limit exceeded',
            SCA003: 'Unable to add this product to cart',
            WARNING_SELECT_DELIVERY_METHOD: 'Please select a delivery method',
            required: 'Requried field',
            WARNING_SELECT_PAYMENT_METHOD: 'Please select a payment method',
            WARNING_ACCEPT_TNC: 'Please accept the Terms and Conditions',
            NOT_ENOUGH_POINTS: 'Not enough points to redeem gift',
            POINTS: ' Point(s)',
            PRICE_UP_PRE: '',
            PRICE_UP_OFF: 'up',
            LOGIN_SING_UP : 'Login / register as a member',
            SHOPPING_CART_CHANGED : 'Item status / content changed, shopping cart is refreshed.',
            VIEW_CART : 'View Cart',
            MEMBER_POINT_PRICE: 'Points Redemption Price',
            PROMOTION_BUTTON: 'Promotion Details',
            PROMOTION_DESC: 'Promotion Details',
            PROMOTION_TAG_CONDITION: 'Promotional Condition Product',
            PROMOTION_TAG_PROMOTION: 'Promotion',
            BUY_NOW: 'Buy now',
            PROMOTION: 'Promotion',
            NOT_PROMOTION: 'Non-related Promotion',
            TO_DATE: 'Promotion period: {{startAt}} to {{endAt}}',
            FROM_DATE: 'Promotion period: From now till {{endAt}}',
            BRANCH_SEARCH:'Branch Address',
            BRANCH_PHONE : 'Phone: ',
            BRANCH_EMAIL : 'Email: ',
            SELECT_DEFAULT: 'Choose',
            SELECT_ALL: 'All',
            FILTER_CONDITION: 'Filter Conditions',
            FILTER_CLEAR: 'Clear All',
            FILTER_CONFIRM: 'Filter',
            PROMOTION_CODE: 'Promotion Code',
            APPLY: 'Apply',
            IF_APPLICABLE: 'If Applicable',
            RECOMMENDED_PRODUCT: 'Suggested Products',
            VIEW_MORE : 'View More',
            ACCEPT_TNC : 'I hereby confirm that I have read and understood the <a href="{{tncLink}}" target="_blank">Terms and Conditions</a>. Accept and agree Compliance with the Terms and Conditions.',
            GLOBAL_PAYMENT_ERROR_2XX : 'Transaction rejected, please contact your bank.',
            GLOBAL_PAYMENT_ERROR_OTHER : 'Transaction unsuccessful, please try again.',
            INVALID_PROMOTION_CODE: 'Invalid promotion code',
            ERROR_RELOAD: 'An unknown error occurred, click here to reload',
        },
    },
    tc: {
        translation: {
            Categories: '商品分類',
            Sorting: '商品排序',
            recommended: '推薦產品',
            priceDesc: '售價(高至低)',
            priceAsc: '售價(低至高)',
            timeDesc: '上架時間(新至舊)',
            timeAsc: '上架時間(舊至新)',
            Search: '搜尋',
            'Terms and conditions': '條款及細則',
            'Privacy policy': '私隱政策',
            TECH_SUPPORT: '© {{year}}, {{shopName}} | 由 Mobile.Cards 技術支援',
            TECH_SUPPORT_MOBILE: '© {{year}}, {{shopName}} | 由 Mobile.Cards 技術支援 | <a href="{{tncLink}}">條款及細則</a> | <a href="{{privacyLink}}">私隱政策</a>', 
            About: '關於',
            Us: '關於我們',
            Address: '門市地址',
            Membership: '會籍詳情',
            Faq: '顧客須知',
            'Delivery policy': '送貨政策',
            'Return policy': '退換貨政策',
            'Shopping cart': '我的購物車',
            Item: '商品',
            Price: '單價',
            Quantity: '數量',
            Subtotal: '小計',
            'Shopping Bag': '購物袋',
            'Delivery fee': '運費',
            Total: '合計',
            'Used points': '使用積分',
            ':': ':',
            'Continue to checkout': '繼續結帳',
            'Checkout': '結帳',
            Contacts: '聯絡我們',
            Color: '顏色',
            'Add to cart': '加入購物車',
            'Add to favorite': '收藏商品',
            'Added to favorite': '已收藏商品',
            Description: '商品詳情',
            'Delivery and payment method': '送貨及付款方式',
            SOLDOUT: '售罄',
            FEW: '尚餘少量',
            TOTAL_PRODUCTS: '共有{{total}}件產品',
            MEMBER_GREETING: '{{name}}, 你好',
            'Logged in member': '已登錄會員',
            'Earned points': '是次消費可獲得積分',
            'Current points': '現有積分',
            'Points expiry date': '積分到期日',
            'Order details': '訂單摘要',
            'Contact infomation': '基本資料',
            Delivery: '運送',
            Payment: '付款',
            FORM: {
                Name: '姓名',
                'Country code': '區碼',
                'Phone number': '電話號碼',
                'Email address': '電子郵件',
                'Delivery address': '運送地址',
                'Country or region': '國家/地區',
                Area: '區域',
                'Address line 1': '地址1',
                'Address line 2': '地址2',
                'Recipient information': '收件人資訊',
                'Save address': '儲存地址供下次使用',
                'Same as above': '收件人資訊與會員資料相同',
                ENTER: '請輸入',
                INVALID_INPUT: '錯誤的',
                preorder: '預訂',
                delivery_date: '預計送貨日期',
                delivery_time: '預計送貨時間',
            },
            'Go to next step': '下一步',
            'Go to delivery': '前往運送設定',
            'Back to shopping cart': '返回我的購物車',
            'Go to payment': '前往付款',
            Back: '返回上一步',
            'Delivery address': '運送地址',
            'Delivery options': '運送選項',
            'Delivery method': '運送方法',
            Pay: '付款',
            'Payment methods': '付款方法',
            'Payment failed, please try again': '付款失敗，請重試',
            'Your order has been received': '成功確認訂單',
            'Back to shop': '繼續購物',
            'Order information': '訂單資訊',
            EMAIL_ORDER: '資料將會發送至閣下的電郵。',
            'Order no.': '訂單編號',
            'Order date': '訂單日期',
            'Payment status': '付款狀態',
            'Delivery status': '送貨狀態',
            'Payment method': '付款方法',
            'Transaction no.': '交易序號',
            'Customer name': '顧客姓名',
            'Recipient name': '收件人姓名',
            'Recipient phone': '收件人聯絡電話',
            'Recipient email': '收件人聯絡電郵',
            Member: '會員',
            'My orders': '我的訂單',
            'Order records': '訂單紀錄',
            'Order status here': '你可以在這裏檢查訂單狀態。',
            ORDER_DETAIL_BUTTON: '查看訂單',
            DETAIL_BUTTON: '立即查看',
            'My favorite': '我的收藏',
            ADDED_TO_CART: '已加入貨品',
            'Logout': '登出',
            en: 'English',
            tc: '繁體中文',
            sc: '简体中文',
            th: 'ไทย',
            'Track code': '追蹤編號',
            'Shopping cart is empty': '購物車上沒有貨品',
            'Up to pieces': '單筆最多可買{{quantity}}件',
            'Up to piece': '單筆最多可買{{quantity}}件',
            'Remain piece': '仍可加{{quantity}}件到購物車',
            'Remain pieces': '仍可加{{quantity}}件到購物車',
            'Purchase limit reached': '已達到購買上限',
            WARNING_MEMBER_POINTS_REQUIRED:
                '此產品需要以積分換領，請登入／登記成為會員。',
            WARNING_MEMBER_REQUIRED: '請登入／登記成為會員。',
            WARNING_MEMBER_GRADE_REQUIRED: '此產品只限會員才可購買。',
            WARNING_MEMBER_GRADE_NOT_ELIGIBLE: '以下會員等級才可購買此產品：',
            TITLE_DOWNLOAD_APP:'同時下載手機會員APP',
            FOOTER_DOWNLOAD_APP:
                '只需輸入手機號碼，經短訊確認後直接開通手機會員卡，消費積分及紀錄盡在掌握，換領心愛禮品目標一目了然。忘記帶會員卡？不再發生。',
            ORDER_STATUS_UPDATED: '訂單狀態已更新',
            PICKUP_QRCODE: '取貨時出示以下QR Code',
            Remark: '備註',
            SKU: '產品編號',
            Confirm: '確認',
            'Purchase limit exceeded': '超過可購買上限',
            SCA003: '無法將此產品添加到購物車',
            WARNING_SELECT_DELIVERY_METHOD: '請選擇送貨方式',
            required: '必填項',
            WARNING_SELECT_PAYMENT_METHOD: '請選擇付款方式',
            WARNING_ACCEPT_TNC: '請同意條款及細則',
            NOT_ENOUGH_POINTS: '沒有足夠積分兌換禮品',
            POINTS: '分',
            PRICE_UP_PRE: '',
            PRICE_UP_OFF: '起',
            LOGIN_SING_UP : '登入／登記成為會員',
            SHOPPING_CART_CHANGED : '商品狀態/內容更改，購物車已被更新。',
            VIEW_CART : '查看購物車',
            BRANCH_SEARCH:'分店地址',
            BRANCH_PHONE : '電話：',
            BRANCH_EMAIL : '電郵：',
            SELECT_DEFAULT: '選擇',
            SELECT_ALL: '全部',
            FILTER_CONDITION: '篩選條件',
            FILTER_CLEAR: '清除所有',
            FILTER_CONFIRM: '篩選',
            MEMBER_POINT_PRICE: '積分換購價',
            PROMOTION_BUTTON: '選購優惠',
            PROMOTION_DESC: '優惠詳情',
            PROMOTION_TAG_CONDITION: '優惠條件產品',
            PROMOTION_TAG_PROMOTION: '優惠產品',
            BUY_NOW: '快速選購',
            PROMOTION: '優惠',
            NOT_PROMOTION: '非相關優惠',
            TO_DATE: '優惠期間：{{startAt}}至{{endAt}}',
            FROM_DATE: '優惠期間：由即日起至{{endAt}}',
            PROMOTION_CODE: '優惠碼',
            APPLY: '使用',
            IF_APPLICABLE: '如適用',
            RECOMMENDED_PRODUCT: '推薦產品',
            VIEW_MORE : '查看更多',
            ACCEPT_TNC : '本人確認本人已詳閲及明白<a href="{{tncLink}}" target="_blank">條款及細則</a>。<br/>本人接受並同意遵從條款及細則。',
            GLOBAL_PAYMENT_ERROR_2XX : '交易被拒絕，請聯絡您的銀行。',
            GLOBAL_PAYMENT_ERROR_OTHER : '交易失敗，請重試。',
            INVALID_PROMOTION_CODE: '優惠碼無效',
            ERROR_RELOAD: '發生未知錯誤，按此重新載入',
        },
    },
    sc: {
        translation: {
            Categories: '商品分类',
            Sorting: '商品排序',
            recommended: '推荐产品',
            priceDesc: '售价(高至低)',
            priceAsc: '上架时间(旧至新)',
            timeDesc: '上架时间(新至旧)',
            timeAsc: 'timeAsc',
            Search: '搜寻',
            'Terms and conditions': '条款及细则',
            'Privacy policy': '私隐政策',
            TECH_SUPPORT: '© {{year}}, {{shopName}} | 由 Mobile.Cards 技术支援',
            TECH_SUPPORT_MOBILE: '© {{year}}, {{shopName}} | 由 Mobile.Cards 技术支援 | <a href="{{tncLink}}">条款及细则</a> | <a href="{{privacyLink}}">私隐政策</a>', 
            About: '关于',
            Us: '关于我们',
            Address: '门市地址',
            Membership: '会籍详情',
            Faq: '顾客须知',
            'Delivery policy': '送货政策',
            'Return policy': '退换货政策',
            'Shopping cart': '我的购物车',
            Item: '商品',
            Price: '单价',
            Quantity: '数量',
            Subtotal: '小计',
            'Shopping Bag': '购物袋',
            'Delivery fee': '运费',
            Total: '合计',
            'Used points': '使用积分',
            ':': ':',
            'Continue to checkout': '继续结帐',
            'Checkout': '结帐',
            Contacts: '联络我们',
            Color: '颜色',
            'Add to cart': '加入购物车',
            'Add to favorite': '收藏商品',
            'Added to favorite': '已收藏商品',
            Description: '商品详情',
            'Delivery and payment method': '送货及付款方式',
            SOLDOUT: '售罄',
            FEW: '尚余少量',
            TOTAL_PRODUCTS: '共有{{total}}件产品',
            MEMBER_GREETING: '{{name}}, 你好',
            'Logged in member': '已登录会员',
            'Earned points': '是次消费可获得积分',
            'Current points': '现有积分',
            'Points expiry date': '积分到期日',
            'Order details': '订单摘要',
            'Contact infomation': '基本资料',
            Delivery: '运送',
            Payment: '付款',
            FORM: {
                Name: '姓名',
                'Country code': '区码',
                'Phone number': '电话号码',
                'Email address': '电子邮件',
                'Delivery address': '运送地址',
                'Country or region': '国家/地区',
                Area: '区域',
                'Address line 1': '地址1',
                'Address line 2': '地址2',
                'Recipient information': '收件人资讯',
                'Save address': '储存地址供下次使用',
                'Same as above': '收件人资讯与会员资料相同',
                ENTER: '请输入',
                INVALID_INPUT: '错误的',
                preorder: '预订',
                delivery_date: '预计送货日期',
                delivery_time: '预计送货时间',
            },
            'Go to next step': '下一步',
            'Go to delivery': '前往运送设定',
            'Back to shopping cart': '返回我的购物车',
            'Go to payment': '前往付款',
            Back: '返回上一步',
            'Delivery address': '运送地址',
            'Delivery options': '运送选项',
            'Delivery method': '运送方法',
            Pay: '付款',
            'Payment methods': '付款方法',
            'Payment failed, please try again': '付款失败，请重试',
            'Your order has been received': '成功确认订单',
            'Back to shop': '继续购物',
            'Order information': '订单资讯',
            EMAIL_ORDER: '资料将会发送至阁客的电邮。',
            'Order no.': '订单编号',
            'Order date': '订单日期',
            'Payment status': '付款状态',
            'Delivery status': '送货状态',
            'Payment method': '付款方法',
            'Transaction no.': '交易序号',
            'Customer name': '顾客姓名',
            'Recipient name': '收件人姓名',
            'Recipient phone': '收件人联络电话',
            'Recipient email': '收件人联络电邮',
            Member: '会员',
            'My orders': '我的订单',
            'Order records': '订单纪录',
            'Order status here': '你可以在这里检查订单状态。',
            ORDER_DETAIL_BUTTON: '查看订单',
            DETAIL_BUTTON: '立即查看',
            'My favorite': '我的收藏',
            ADDED_TO_CART: '已加入货品',
            'Logout': '登出',
            en: 'English',
            tc: '繁体中文',
            sc: '简体中文',
            th: 'ไทย',
            'Track code': '追踪编号',
            'Shopping cart is empty': '购物车上没有货品',
            'Up to pieces': '单笔最多可买{{quantity}}件',
            'Up to piece': '单笔最多可买{{quantity}}件',
            'Remain piece': '仍可加{{quantity}}件到购物车',
            'Remain pieces': '仍可加{{quantity}}件到购物车',
            'Purchase limit reached': '已达到购买上限',
            WARNING_MEMBER_POINTS_REQUIRED:
                '此产品需要以积分换领，请登入／登记成为会员。',
            WARNING_MEMBER_REQUIRED: '请登入／登记成为会员。',
            WARNING_MEMBER_GRADE_REQUIRED: '此产品只限会员才可购买。',
            WARNING_MEMBER_GRADE_NOT_ELIGIBLE: '以下会员等级才可购买此产品：',
            TITLE_DOWNLOAD_APP:'同时下载手机会员APP',
            FOOTER_DOWNLOAD_APP:
                '只需输入手机号码，经短讯确认后直接开通手机会员卡，消费积分及纪录尽在掌握，换领心爱礼品目标一目了然。忘记带会员卡？不再发生。',
            ORDER_STATUS_UPDATED: '订单状态已更新',
            PICKUP_QRCODE: '取货时出示以下QR Code',
            Remark: '备注',
            SKU: '产品编号',
            Confirm: '确认',
            'Purchase limit exceeded': '超过可购买上限',
            SCA003: '无法将此产品添加到购物车',
            WARNING_SELECT_DELIVERY_METHOD: '请选择送货方式',
            required: '必填項',
            WARNING_SELECT_PAYMENT_METHOD: '请选择付款方式',
            WARNING_ACCEPT_TNC: '请同意条款及细则',
            NOT_ENOUGH_POINTS: '没有足够积分兑换礼品',
            POINTS: '分',
            PRICE_UP_PRE: '',
            PRICE_UP_OFF: '起',
            LOGIN_SING_UP : '登入／登记成为会员',
            SHOPPING_CART_CHANGED : '商品状态/内容更改，购物车已被更新。',
            VIEW_CART : '查看购物车',
            MEMBER_POINT_PRICE: '积分换购价',
            PROMOTION_BUTTON: '选购优惠',
            PROMOTION_DESC: '优惠详情',
            PROMOTION_LABEL: 'Test',
            PROMOTION_TAG_CONDITION: '优惠条件产品',
            PROMOTION_TAG_PROMOTION: '优惠产品',
            BUY_NOW: '快速选购',
            PROMOTION: '优惠',
            NOT_PROMOTION: '非相关优惠',
            TO_DATE: '优惠期间：{{startAt}}至{{endAt}}',
            FROM_DATE: '优惠期间：由即日起至{{endAt}}',
            BRANCH_SEARCH:'分店地址',
            BRANCH_PHONE : '电话：',
            BRANCH_EMAIL : '电邮',
            SELECT_DEFAULT: '选择',
            SELECT_ALL: '全部',
            FILTER_CONDITION: '筛选条件',
            FILTER_CLEAR: '清除所有',
            FILTER_CONFIRM: '筛选',
            PROMOTION_CODE: '优惠码',
            APPLY: '使用',
            IF_APPLICABLE: '如适用',
            RECOMMENDED_PRODUCT: '推荐产品',
            VIEW_MORE : '查看更多',
            ACCEPT_TNC : '本人确认本人已详阅及明白<a href="{{tncLink}}" target="_blank">条款及细则</a>。<br/>本人接受并同意遵从条款及细则。',
            GLOBAL_PAYMENT_ERROR_2XX : '交易被拒絕，請聯絡您的銀行。',
            GLOBAL_PAYMENT_ERROR_OTHER : '交易失敗，請重試。',
            INVALID_PROMOTION_CODE: '优惠码无效',
            ERROR_RELOAD: '发生未知错误，按此重新载入',
        },
    },
    th: {
        translation: {
            Categories: 'หมวดสินค้า',
            Sorting: 'การจัดเรียงสินค้า',
            recommended: 'แนะนำ',
            priceDesc: 'ราคา (สูงไปต่ำ)',
            priceAsc: 'ราคา (ต่ำไปสูง)',
            timeDesc: 'เวลาวางจำหน่าย (ใหม่ไปเก่า)',
            timeAsc: 'เวลาวางจำหน่าย (เก่าไปใหม่)',
            Search: 'ค้นหา',
            'Terms and conditions': 'ข้อกำหนดและเงื่อนไข',
            'Privacy policy': 'นโยบายความเป็นส่วนตัว',
            TECH_SUPPORT: '© {{year}}, {{shopName}} | ขับเคลื่อนโดย  Mobile.Cards',
            TECH_SUPPORT_MOBILE: '© {{year}}, {{shopName}} | ขับเคลื่อนโดย  Mobile.Cards | <a href="{{tncLink}}">ข้อกำหนดและเงื่อนไข</a> | <a href="{{privacyLink}}" target="_blank">นโยบายความเป็นส่วนตัว</a>', 
            'Privacy policy': 'เกี่ยวกับ',
            Us: 'เกี่ยวกับเรา',
            Address: 'ที่อยู่ร้านค้า',
            Membership: 'รายละเอียดสมาชิก',
            Faq: 'ประกาศเกี่ยวกับลูกค้า',
            'Delivery policy': 'นโยบายการจัดส่ง',
            'Return policy': 'นโยบายการคืน',
            'Shopping cart': 'ตะกร้าสินค้าของฉัน',
            Item: 'สินค้า',
            Price: 'ราคาต่อหน่วย',
            Quantity: 'ปริมาณ',
            Subtotal: 'ผลรวมย่อย',
            'Shopping Bag': 'กระเป๋าช้อปปิ้ง',
            'Delivery fee': 'ค่าจัดส่ง',
            Total: 'ทั้งหมด',
            'Used points': 'คะแนนที่ใช้',
            ':': ':',
            'Continue to checkout': 'ดำเนินการต่อเพื่อชำระเงิน',
            'Checkout': 'เช็คเอาท์',
            Contacts: 'ติดต่อเรา',
            Color: 'สี',
            'Add to cart': 'หยิบใส่ตะกร้า',
            'Add to favorite': 'รายการโปรด',
            'Added to favorite': 'รายการโปรด',
            Description: 'รายละเอียดสินค้า',
            'Delivery and payment method': 'วิธีจัดส่งและชำระเงิน',
            SOLDOUT: 'สินค้าหมด',
            FEW: 'เหลือสินค้าเพียงไม่กี่รายการ',
            TOTAL_PRODUCTS: 'A total of {{total}} products',
            MEMBER_GREETING: '{{ชื่อ}}, สวัสดี',
            'Logged in member': 'เข้าสู่ระบบสมาชิก',
            'Eearned points': 'คุณสามารถรับคะแนนสำหรับการบริโภคนี้',
            'Current points': 'จุดปัจจุบัน',
            'Points expiry date': 'วันหมดอายุของคะแนน',
            'Order details': 'สรุปการสั่งซื้อ',
            'Contact ข้อมูล': 'ข้อมูลพื้นฐาน',
            Delivery: 'การจัดส่ง',
            Payment: 'การชำระเงิน',
            FORM: {
                Name: 'ชื่อ',
                'Country code': 'รหัสพื้นที่',
                'Phone number': 'หมายเลขโทรศัพท์',
                'Email address': 'อีเมล',
                'Delivery address': 'ที่อยู่จัดส่ง',
                'Country or region': 'ประเทศ/ภูมิภาค',
                Area: 'พื้นที่',
                'Address line 1': 'ที่อยู่ 1',
                'Address line 2': 'ที่อยู่ 2',
                'Recipient information': 'ข้อมูลผู้รับ',
                'Save address': 'บันทึกที่อยู่เพื่อใช้ครั้งต่อไป',
                'Same as above': 'ข้อมูลผู้รับเหมือนกับข้อมูลสมาชิก',
                ENTER: 'กรุณาป้อน',
                INVALID_INPUT: 'ผิด',
                preorder: 'preorder',
                delivery_date: 'วันที่คาดว่าจะจัดส่ง',
                delivery_time: 'เวลาจัดส่งที่คาดหวัง',
            },
            'Go to next step': 'ถัดไป',
            'Go to delivery': 'ไปที่การตั้งค่าการจัดส่ง',
            'Back to shopping cart': 'กลับไปที่ตะกร้าสินค้าของฉัน',
            'Go to payment': 'ไปที่การชำระเงิน',
            Back: 'ย้อนกลับไปยังขั้นตอนก่อนหน้า',
            'Delivery address': 'ที่อยู่จัดส่ง',
            'Delivery options': 'ตัวเลือกการจัดส่ง',
            'Delivery method': 'วิธีการจัดส่ง',
            Pay: 'การชำระเงิน',
            'Payment methods': 'วิธีการชำระเงิน',
            'Payment failed, please try again':
                'การชำระเงินล้มเหลว โปรดลองใหม่อีกครั้ง',
            'Your order has been received': 'ยืนยันการสั่งซื้อเรียบร้อยแล้ว',
            'Back to shop': 'เลือกซื้อสินค้าต่อ',
            'Order information': 'ข้อมูลการสั่งซื้อ',
            EMAIL_ORDER: 'ข้อมูลจะถูกส่งไปที่อีเมลของคุณ',
            'Order no.': 'หมายเลขคำสั่งซื้อ',
            'Order date': 'วันที่สั่งซื้อ',
            'Payment status': 'สถานะการชำระเงิน',
            'Delivery status': 'สถานะการจัดส่ง',
            'Payment method': 'วิธีการชำระเงิน',
            'Transaction no.': 'หมายเลขธุรกรรม',
            'Customer name': 'ชื่อลูกค้า',
            'Recipient name': 'ชื่อผู้รับ',
            'Recipient phone': 'หมายเลขโทรศัพท์ของผู้รับ',
            'Recipient email': 'อีเมลติดต่อผู้รับ',
            Member: 'สมาชิก',
            'My orders': 'คำสั่งของฉัน',
            'Order records': 'บันทึกการสั่งซื้อ',
            'Order status here': 'คุณสามารถตรวจสอบสถานะการสั่งซื้อได้ที่นี่',
            ORDER_DETAIL_BUTTON: 'ดูคำสั่งซื้อ',
            DETAIL_BUTTON: 'ดูตอนนี้',
            'My favorite': 'My Favorite',
            ADDED_TO_CART: 'สินค้าที่เพิ่ม',
            'Logout': 'ออกจากระบบ',
            en: 'English',
            tc: '繁体中文',
            sc: '简体中文',
            th: 'ไทย',
            'Track code': 'หมายเลขติดตาม',
            'Shopping cart is empty': 'ไม่มีสินค้าในตะกร้าสินค้า',
            'Up to pieces': 'คุณสามารถซื้อได้สูงสุด {{quantity}} ชิ้น',
            'Up to piece': 'คุณสามารถซื้อได้สูงสุด {{quantity}} ชิ้น',
            'Remain piece': 'ยังคงสามารถเพิ่ม {{quantity}} ชิ้นลงรถเข็นได้',
            'Remain pieces': 'ยังคงสามารถเพิ่ม {{quantity}} ชิ้นลงรถเข็นได้',
            'Purchase limit reached': 'ถึงขีดจำกัดการซื้อแล้ว',
            WARNING_MEMBER_POINTS_REQUIRED:
                'สินค้านี้จำเป็นต้องซื้อด้วยคะแนน กรุณาเข้าสู่ระบบ / ลงทะเบียนเป็นสมาชิก',
            WARNING_MEMBER_REQUIRED: 'กรุณาเข้าสู่ระบบ / ลงทะเบียนเป็นสมาชิก ',
            WARNING_MEMBER_GRADE_REQUIRED: 'สินค้านี้สำหรับสมาชิกเท่านั้น',
            WARNING_MEMBER_GRADE_NOT_ELIGIBLE: 'สมาชิกต่อไปนี้สามารถซื้อผลิตภัณฑ์นี้ได้:',
            TITLE_DOWNLOAD_APP:'ดาวน์โหลดแอพสมาชิก',
            FOOTER_DOWNLOAD_APP:
                'รับคะแนนสะสมทุกครั้งที่คุณทำการสั่งซื้อและเพลิดเพลินกับข้อเสนอทั้งหมดของสมาชิก! สิ่งที่คุณต้องทำคือเข้าร่วมกับสมาชิกด้วยหมายเลขโทรศัพท์ไม่จำเป็นต้องใช้รหัสผ่าน',
            ORDER_STATUS_UPDATED: 'อัปเดตสถานะการสั่งซื้อแล้ว',
            PICKUP_QRCODE: 'แสดง QR Code ต่อไปนี้เมื่อรับสินค้า',
            Remark: 'สังเกต',
            SKU: 'SKU',
            Confirm: 'ยืนยัน',
            'Purchase limit exceeded': 'เกินขีดจำกัดการซื้อ',
            SCA003: 'ไม่สามารถเพิ่มสินค้านี้ลงในรถเข็น',
            WARNING_SELECT_DELIVERY_METHOD: 'โปรดเลือกวิธีการจัดส่ง',
            required: 'ฟิลด์ที่ต้องกรอก',
            WARNING_SELECT_PAYMENT_METHOD: 'โปรดเลือกวิธีการชำระเงิน',
            WARNING_ACCEPT_TNC: 'โปรดยอมรับข้อกำหนดและเงื่อนไข',
            NOT_ENOUGH_POINTS: 'คะแนนไม่เพียงพอที่จะแลกของขวัญ',
            POINTS: 'จุด',
            PRICE_UP_PRE: 'จาก ',
            PRICE_UP_OFF: '',
            LOGIN_SING_UP : 'เข้าสู่ระบบ / สมัครสมาชิก',
            SHOPPING_CART_CHANGED : 'เปลี่ยนสถานะสินค้า / เนื้อหา ตะกร้าสินค้าถูกรีเฟรช',
            VIEW_CART : 'ตรวจสอบตะกร้าสินค้า',
            BRANCH_SEARCH:'Bที่อยู่สาขา',
            BRANCH_PHONE : 'โทรศัพท์: ',
            BRANCH_EMAIL : 'อีเมล: ',
            SELECT_DEFAULT: 'เลือก',
            SELECT_ALL: 'ทั้งหมด',
            FILTER_CONDITION: 'เงื่อนไขการกรอง',
            FILTER_CLEAR: 'ลบทั้งหมด',
            FILTER_CONFIRM: 'กรอง',
            MEMBER_POINT_PRICE: 'ราคาแลกคะแนน',
            PROMOTION_BUTTON: 'ซื้อโปรโมชัน',
            PROMOTION_DESC: 'รายละเอียดโปรโมชัน',
            PROMOTION_TAG_CONDITION: 'สินค้าตามเงื่อนไขโปรโมชัน',
            PROMOTION_TAG_PROMOTION: 'ผลิตภัณฑ์ส่งเสริมการขาย',
            BUY_NOW: 'ซื้อด่วน',
            PROMOTION: 'โปรโมชั่น',
            NOT_PROMOTION: 'โปรโมชันที่ไม่เกี่ยวข้อง',
            TO_DATE: 'ช่วงโปรโมชั่น: {{startAt}} ถึง {{endAt}}',
            FROM_DATE: 'ช่วงโปรโมชั่น: ตั้งแต่บัดนี้เป็นต้นไป {{endAt}}',
            PROMOTION_CODE: 'รหัสส่งเสริมการขาย',
            APPLY: 'ใช้',
            IF_APPLICABLE: 'ถ้ามี',
            RECOMMENDED_PRODUCT: 'สินค้าแนะนำ',
            VIEW_MORE : 'ดูเพิ่มเติม',
            ACCEPT_TNC : 'I hereby confirm that I have read and understood the <a href="{{tncLink}}" target="_blank">Terms and Conditions</a>. Accept and agree Compliance with the Terms and Conditions.',
            GLOBAL_PAYMENT_ERROR_2XX : 'Transaction rejected, please contact your bank.',
            GLOBAL_PAYMENT_ERROR_OTHER : 'Transaction unsuccessful, please try again.',
            INVALID_PROMOTION_CODE: 'รหัสส่งเสริมการขายไม่ถูกต้อง',
            ERROR_RELOAD: 'เกิดข้อผิดพลาดที่ไม่ทราบสาเหตุ คลิกที่นี่เพื่อโหลดซ้ำ',
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: AVAILBLE_LOCALES.en,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
