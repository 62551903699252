import { VStack } from 'native-base';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import useMeasure from 'react-use-measure';
import CheckoutMemberInfo from './CheckoutMemberInfo';
import Separator from './Separator';
import ShoppingCartCalculationView from './ShoppingCartCalculationView';
import ShoppingCartItemTable from './ShoppingCartItemTable';
import PromotionCodeInput from './PromotionCodeInput';

export const CheckoutItemListType = {
    normal: 'normal',
    record: 'record',
};

const CheckoutItemList = ({
    onLayout = () => {},
    style,
    data,
    subTotal,
    shippingCharge,
    finalShippingCharge,
    total,
    hideMemberInfo = false,
    hideBorder = false,
    totalPoint,
    memberInfo = {},
    remark,
    promotions,
    shippingChargePromotions,
    showPromotionInput = false,
    promotionCode,
}) => {
    const [ref, { height }] = useMeasure();

    useEffect(() => {
        onLayout(height);
    }, [height]);

    return (
        <VStack
            ref={ref}
            borderRadius={'xl'}
            borderColor={'#E5E5E5'}
            borderWidth={hideBorder ? 0 : '1'}
            backgroundColor={hideBorder ? 'transparent' : '#FAFAFA'}
            overflow={'hidden'}
            style={style}>
            <VStack margin={'5'} space={'5'}>
                <View>
                    <ShoppingCartItemTable
                        data={data}
                        hideTitle={hideMemberInfo}
                    />
                    {showPromotionInput && (
                        <PromotionCodeInput />
                    )}
                    <ShoppingCartCalculationView
                        subTotal={subTotal}
                        shippingCharge={shippingCharge}
                        finalShippingCharge={finalShippingCharge}
                        total={total}
                        totalPoint={totalPoint}
                        remark={remark}
                        promotions={promotions}
                        shippingChargePromotions={shippingChargePromotions}
                        promotionCode={promotionCode}
                    />
                </View>
                {!hideMemberInfo && (
                    <>
                        <Separator />
                        <CheckoutMemberInfo />
                    </>
                )}
            </VStack>
        </VStack>
    );
};

export default CheckoutItemList;
