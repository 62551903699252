import { Center, Text, VStack } from 'native-base';
import React from 'react';
import { useParams } from 'react-router-dom';
import CenterLineTitle from '../components/CenterLineTItle';
import CheckoutItemList from '../components/CheckoutItemList';
import CheckoutMemberInfo from '../components/CheckoutMemberInfo';
import OrderDetailContactView from '../components/OrderDetailContactView';
import { useOrderDetail } from '../queries/orders';
import { useIsMobile } from '../utils/hooks';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

const MyRecordScreen = ({ route }) => {
    const { orderNo } = useParams();
    const { data: record } = useOrderDetail(orderNo);

    const {
        inventories,
        subtotal,
        shippingCharge,
        finalShippingCharge,
        total,
        totalPoint,
        earnPoints,
        pickupQrcode,
        remark,
        promotions,
        shippingChargePromotions,
        promotionCode,
    } = record;

    const isMobile = useIsMobile();

    const { t } = useTranslation();

    return (
        <VStack space={'5'} marginRight={isMobile ? 0 : '1/5'}>
            <Text bold fontSize={'3xl'}>{`#${orderNo}`}</Text>
            {pickupQrcode && (
                <Center>
                    <QRCode size={200} value={pickupQrcode} />
                </Center>
            )}
            <CheckoutMemberInfo earnPoints={earnPoints} />
            <CenterLineTitle title={t('Order information')} />
            <OrderDetailContactView order={record} />
            <CenterLineTitle title={t('Order details')} />
            <CheckoutItemList
                data={inventories}
                subTotal={subtotal}
                shippingCharge={shippingCharge}
                finalShippingCharge={finalShippingCharge}
                total={total}
                hideMemberInfo={true}
                hideBorder={true}
                totalPoint={totalPoint}
                remark={remark}
                promotions={promotions}
                shippingChargePromotions={shippingChargePromotions}
                promotionCode={promotionCode}
            />
        </VStack>
    );
};

export default MyRecordScreen;
