import chroma from 'chroma-js';
import { NativeBaseProvider, ScrollView } from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useOutlet } from 'react-router-dom';
import { useIsFirstRender } from 'usehooks-ts';
import NavigationHeader from './components/NavigationHeader';
import { RNCenterLoading } from './components/RNCenterLoading';
import { useShopInfo } from './queries/shop';
import { mainTheme } from './theme';

let scrollOffsetHistory = {};

const LIGHTNESS_MAP = [
    0.95, 0.85, 0.75, 0.65, 0.55, 0.45, 0.35, 0.25, 0.15, 0.05,
];

const genColors = (color = '#000000') => {
    return LIGHTNESS_MAP.map(l => chroma(color).saturate(l).hex());
};

export default () => {
    const { scrollY, updatedAt } = useSelector(state => state.ui);
    const {
        data: shopInfo,
        isLoading: isLoadingShopInfo,
        isError,
        error,
    } = useShopInfo();
    const { colorTone } = shopInfo;

    const [headerHeight, setHeaderHeight] = useState(0);
    let scrollRestored = true;

    const outlet = useOutlet({
        headerHeight,
    });

    const scrollViewRef = useRef(null);

    const location = useLocation();
    const { pathname, key } = location;

    const isFirstRender = useIsFirstRender();

    const [colors, setColors] = useState([]);

    useEffect(() => {
        setColors(genColors(colorTone));
    }, [colorTone]);

    useEffect(() => {
        if (!scrollOffsetHistory[key]) {
            scrollViewRef.current?.scrollTo({ y: 0, animated: false });
        } else {
            scrollRestored = false;
        }
    }, [key]);

    useEffect(() => {
        if (!isFirstRender && typeof scrollY === 'number' && updatedAt) {
            scrollViewRef.current?.scrollTo({ y: scrollY, animated: true });
        }
    }, [scrollY, updatedAt]);

    if (isLoadingShopInfo || isError) {
        let messages = '';

        if (error?.response?.data?.errorMsgs) {
            messages = error.response.data.errorMsgs
                .map(error => error.message)
                .concat('\n');
        }

        return <RNCenterLoading error={messages} />;
    }

    return (
        <NativeBaseProvider
            theme={mainTheme({
                colorTone,
                colors,
            })}>
            <ScrollView
                ref={scrollViewRef}
                overflowY={'scroll'}
                onScroll={e => {
                    scrollOffsetHistory[key] = e.nativeEvent.contentOffset.y;
                }}
                onContentSizeChange={(w, h) => {
                    if (
                        scrollOffsetHistory[key] &&
                        h > scrollOffsetHistory[key] &&
                        !scrollRestored
                    ) {
                        scrollRestored = true;
                        scrollViewRef.current.scrollTo({
                            y: scrollOffsetHistory[key],
                            animated: false,
                        });
                    }
                }}
                scrollEventThrottle={16}
                stickyHeaderIndices={[0]}>
                <NavigationHeader onHeightChanged={setHeaderHeight} />
                {outlet}
            </ScrollView>
        </NativeBaseProvider>
    );
};
