import {
    MutationCache,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { NativeBaseProvider, Toast } from 'native-base';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import { ShopApiInterceptor } from './api/shopApiClient';
import Router from './Router';
import store from './store';

let persistor = persistStore(store);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 30000,
            retry: false,
        },
        mutations: {
            retry: false,
        },
    },
    mutationCache: new MutationCache({
        onError: error => {
            if (error?.response?.request?.responseURL.includes('pendingOrder')){
              return;
            }
            if (error?.response?.data?.errorMsgs) {
                error.response.data.errorMsgs.forEach(({ code, message }) => {
                    let description = message;

                    if (code === 'SCA003' || code === 'LoggedIn') { // handled in useMutation result
                        return;
                    }

                    Toast.show({
                        description,
                    });
                });
            }
        },
    }),
});

const App = () => {
    return (
        <>
            <style type="text/css">{`
      @font-face {
        font-family: 'FontAwesome';
        src: url(${require('react-native-vector-icons/Fonts/FontAwesome.ttf')}) format('truetype');
      }
        @font-face {
          font-family: 'FontAwesome5_Regular';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Solid';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'FontAwesome5_Brands';
          src: url(${require('react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'Feather';
          src: url(${require('react-native-vector-icons/Fonts/Feather.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'EvilIcons';
          src: url(${require('react-native-vector-icons/Fonts/EvilIcons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'Ionicons';
          src: url(${require('react-native-vector-icons/Fonts/Ionicons.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'AntDesign';
          src: url(${require('react-native-vector-icons/Fonts/AntDesign.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'Fontisto';
          src: url(${require('react-native-vector-icons/Fonts/Fontisto.ttf')}) format('truetype');
        }
        @font-face {
          font-family: 'Octicons';
          src: url(${require('react-native-vector-icons/Fonts/Octicons.ttf')}) format('truetype');
        }
      `}</style>
            <HelmetProvider>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <QueryClientProvider client={queryClient}>
                            <NativeBaseProvider>
                              <ShopApiInterceptor>
                                <CookiesProvider>
                                  <Router queryClient={queryClient} />
                                </CookiesProvider>
                              </ShopApiInterceptor>
                            </NativeBaseProvider>
                        </QueryClientProvider>
                    </PersistGate>
                </Provider>
            </HelmetProvider>
        </>
    );
};

export default App;
